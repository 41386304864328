
import { defineComponent, h, reactive, Component, computed } from "vue";
import { RouterLink, useRoute } from "vue-router";
import {
  NConfigProvider,
  GlobalThemeOverrides,
  NMenu,
  NSpin,
  NIcon,
  MenuOption,
} from "naive-ui";
import {
  CelebrationOutlined,
  DraftsOutlined,
  HotelOutlined,
  CoronavirusOutlined,
} from "@vicons/material";
import Header from "@/components/Header.vue";
import { fetchParty } from "@/services/api";
import { RSVP, RSVPStore } from "@/types";
import Snow from "@/components/rsvp/Snow.vue";

function renderIcon(icon: Component) {
  return () => h(NIcon, null, { default: () => h(icon) });
}

const store: RSVPStore = {
  state: reactive({
    rsvp: {
      id: undefined,
      invitees: [],
      participants: [],
      ceremony: false,
      email: "",
      answered: false,
      message: "",
    },
    loading: true,
    subject: "",
    invitedParty: "",
  }),
  setRsvpData(data: RSVP) {
    this.state.rsvp = {
      ...data,
      participants: [...data.invitees],
    };
  },
  setLoading(loading: boolean) {
    this.state.loading = loading;
  },
  setAnswered(answered: boolean) {
    this.state.rsvp.answered = answered;
  },
};

export default defineComponent({
  name: "RSVP",
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      store: store,
    } as { store: RSVPStore };
  },
  created() {
    this.fetchParty();
  },
  setup() {
    const route = useRoute();
    return {
      activeKey: computed(() => {
        if (/\/answer/.test(route.path)) return "answer";
        if (/\/hotel/.test(route.path)) return "hotel";
        if (/\/covid/.test(route.path)) return "covid";
        else return "invitation";
      }),
    };
  },
  methods: {
    async fetchParty() {
      store.setLoading(true);
      const idParam = this.$route.params.id;
      const id = Array.isArray(idParam) ? idParam[0] : idParam;

      const data = await fetchParty(id);
      store.setRsvpData(data);
      store.setLoading(false);
    },
    getThemeOverrides(): GlobalThemeOverrides {
      return {
        common: {
          primaryColor: "#657688",
          primaryColorHover: "#859cb4",
        },
        Button: {
          textColor: "#2c3e50",
          textColorHover: "#657688",
        },
        Menu: {
          color: "#eeeeee",
          itemTextColor: "#657688",
          itemIconColor: "#657688",
          itemTextColorHover: "#2c3e50",
          itemIconColorHover: "#2c3e50",
          itemTextColorActive: "#2c3e50",
          itemIconColorActive: "#2c3e50",
        },
        Alert: {
          iconColorSuccess: "#859CB4",
          colorSuccess: "#dfebf3",
          borderSuccess: "0 solid #859CB4",
        },
      };
    },
  },
  computed: {
    menuOptions(): Array<MenuOption> {
      return [
        {
          label: () =>
            h(
              RouterLink,
              {
                to: {
                  name: "invitation",
                },
              },
              {
                default: () =>
                  h(
                    "span",
                    { className: "menu-label" },
                    this.$t("rsvp.invitationTab")
                  ),
              }
            ),
          key: "invitation",
          icon: renderIcon(CelebrationOutlined),
        },
        {
          label: () =>
            h(
              RouterLink,
              {
                to: {
                  name: "answer",
                },
              },
              {
                default: () =>
                  h(
                    "span",
                    { className: "menu-label" },
                    this.$t("rsvp.formTab")
                  ),
              }
            ),
          key: "answer",
          icon: renderIcon(DraftsOutlined),
        },
        {
          label: () =>
            h(
              RouterLink,
              {
                to: {
                  name: "hotel",
                },
              },
              {
                default: () =>
                  h(
                    "span",
                    { className: "menu-label" },
                    this.$t("rsvp.hotelTab")
                  ),
              }
            ),
          key: "hotel",
          icon: renderIcon(HotelOutlined),
        },
        {
          label: () =>
            h(
              RouterLink,
              {
                to: {
                  name: "covid",
                },
              },
              {
                default: () =>
                  h(
                    "span",
                    { className: "menu-label" },
                    this.$t("rsvp.covidTab")
                  ),
              }
            ),
          key: "covid",
          icon: renderIcon(CoronavirusOutlined),
        },
      ];
    },
  },
  components: {
    Snow,
    NConfigProvider,
    Header,
    NSpin,
    NMenu,
  },
});
